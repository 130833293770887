import { useRoutes } from "react-router-dom";
import LandingPageLayout from "../layout/LandingPage/LandingPageLayout";
import AboutUs from "../pages/AboutUs/AboutUs";
import ContactUs from "../pages/ContactUs/ContactUs";
import FAQs from "../pages/FAQs/FAQs";
import Home from "../pages/Home/Home";
import LookingForLocums from "../pages/LookingForLocums/LookingForLocums";
import LookingForWork from "../pages/LookingForWork/LookingForWork";
import { NotFound404 } from "../pages/NotFound404";
import {
  ABOUT_US,
  CONTACT_US,
  FAQS,
  HOME,
  LOOKING_FOR_LOCUM,
  LOOKING_FOR_WORK,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  RESET_PASSWORD,
} from "./routeConstants";
import { Terms } from "../pages/Legals/Terms";
import { PrivacyPolicy } from "../pages/Legals/PrivacyPolicy";
import ResetPassword from "../pages/ResetPassword/ResetPassword";

export default function Router() {
  return useRoutes([
    {
      path: HOME,
      element: <LandingPageLayout />,
      children: [
        { path: HOME, element: <Home /> },
        {
          path: ABOUT_US,
          element: <AboutUs />,
        },
        {
          path: CONTACT_US,
          element: <ContactUs />,
        },
        {
          path: FAQS,
          element: <FAQs />,
        },
        {
          path: LOOKING_FOR_LOCUM,
          element: <LookingForLocums />,
        },
        {
          path: LOOKING_FOR_WORK,
          element: <LookingForWork />,
        },
      ],
    },

    { path: "*", element: <NotFound404 /> },
    { path: TERMS_CONDITIONS, element: <Terms /> },
    { path: PRIVACY_POLICY, element: <PrivacyPolicy /> },
    { path: `${RESET_PASSWORD}/:b64uid/:b64token`, element: <ResetPassword /> },
  ]);
}

// /:b64uid/:b64token
