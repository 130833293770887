import * as Yup from "yup";

export const ResetPaswdSchema = Yup.object().shape({
  password: Yup.string().required("must enter a new password"),
});

export type ResetPaswdDataType = Yup.InferType<typeof ResetPaswdSchema>;

type InitialValues = {
  password: string;
};

export const resetPasswordInitialValues: InitialValues = {
  password: "",
};

export interface IPasswordReset {
  password: string;
}
