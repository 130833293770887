import { AxiosError } from "axios";
import { useState } from "react";
import toast from "react-hot-toast";

import {
  ErrorHttpResponse,
  HttpConfig,
  SuccessHttpResponse,
} from "../models/https";
import useHttps from "./useHttps";

export interface VerifyResetResponse {
  b64uid: string;
  b64token: string;
}

const useVerifyResetToken = () => {
  const request = useHttps();
  const [loading, setLoading] = useState(false);

  const onVerifyError = (err: AxiosError<ErrorHttpResponse>) => {
    console.log("onVerifyResetTokenError");
    toast.error(
      err?.response?.data?.message ?? "Invalid or expired reset link."
    );
  };

  const onVerifySuccess = ({
    data,
  }: SuccessHttpResponse<VerifyResetResponse>) => {
    toast.success("Password reset link successfully verified!");
    localStorage.setItem("resetData", JSON.stringify(data));
  };

  const verifyResetToken = (b64uid: string, b64token: string) => {
    const url: HttpConfig = {
      url: `auth-service/users/reset-password/${b64uid}/${b64token}`,
      method: "get",
    };

    request(url, onVerifySuccess, setLoading, onVerifyError);
  };

  return {
    loading,
    verifyResetToken,
  };
};

export default useVerifyResetToken;
