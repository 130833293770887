// Auth Routes
export const ACCOUNT = 'account';
export const SIGNUP = 'signup';
export const SIGNIN = 'signin';
export const SIGNUP_OTP = 'signup-otp';
export const FORGOT_PASSWORD = 'forgot-password';

// Landing-page routes
export const HOME = '/';
export const ABOUT_US = 'about-us';
export const CONTACT_US = 'contact-us';
export const FAQS = 'faqs';
export const LOOKING_FOR_LOCUM = 'looking-for-locum';
export const LOOKING_FOR_WORK = 'looking-for-work';
export const USERDASHBOARDHOME = 'home';
// export const USERDASHBOARDHOME = '';
export const LOCUMJOBS = 'locum-jobs';
export const APPLICATION = 'application';
export const UERS_SETTINGS = 'settings';
// Users Dashboard routes
export const USERS_DASHBOARD = '/u/dashboard';

// Institution Dashboard routes
export const INTITUTION_DASHBOARD = '/i/dashboard';

// Legal routes
export const TERMS_CONDITIONS = 'T&Cs';
export const PRIVACY_POLICY = 'privacypolicy';

// reset password
export const RESET_PASSWORD = '/users/password-reset'

// NotFound
export const NOTFOUND = '404';
