import styled from "@emotion/styled";
import ValidatingFormSubmitButton from "../../components/Button/FormSubmitButton";
import { FormComponent } from "../../components/Form/FormComponent";
import { PasswordInputField } from "../../components/Form/FormField";
import useUserResetPassword from "../../hooks/useResetPassword";
import {
  resetPasswordInitialValues,
  ResetPaswdSchema,
} from "../../models/auth/resetPassword.model";
import { useParams } from "react-router-dom";
import useVerifyResetToken from "../../hooks/useVerifyResetToken";
import { useEffect } from "react";
import CompanyLogo from "../../components/CompanyLogo/CompanyLogo";

const ForgotPassword = () => {
  const { b64uid, b64token } = useParams<{
    b64uid: string;
    b64token: string;
  }>();
  const { verifyResetToken, loading } = useVerifyResetToken();

  const {
    resetUserPassword,
    loading: resetPasswordLoading,
    completed,
  } = useUserResetPassword();

  const onSubmit = (
    values: { password: string },
    { resetForm }: { resetForm: () => void }
  ) => {
    resetUserPassword(values.password);
    resetForm();
  };

  useEffect(() => {
    if (b64uid && b64token) {
      verifyResetToken(b64uid, b64token);
    }
  }, [b64uid, b64token, verifyResetToken]);

  if (loading) return <div>Verifying reset link...</div>;

  return (
    <Container>
      <CompanyLogo />
      <div>
        <h2>Enter NewPassword</h2>
        <FormComponent
          initialValues={resetPasswordInitialValues}
          schema={ResetPaswdSchema}
          onSubmit={onSubmit}
          className={"flex"}
        >
          <PasswordInputField
            name="password"
            id="password"
            type="password"
            placeholder="Enter New Password"
          />

          <ValidatingFormSubmitButton
            className="reset"
            loading={resetPasswordLoading}
          >
            Save New Password
          </ValidatingFormSubmitButton>
        </FormComponent>
        {completed && (
          <p>
            Reset Successful Go to{" "}
            <a href="https://locum-user.web.app/account/signin">SignIn Page</a>
          </p>
        )}
      </div>
    </Container>
  );
};

export default ForgotPassword;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > div {
    box-shadow: -1px 6px 9px 0px rgba(238, 225, 225, 0.58);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    & > h2 {
      font-size: 24px;
    }

    & > p {
      font-size: 18px;
      font-weight: 400;

      & > a {
        color: ${({ theme }) => theme.palette.mainBlue};
      }
    }

    // Mobile-specific styles
    @media (max-width: 768px) {
      & > p {
        font-size: 15px;
      }
    }
  }
`;
