import { useField } from 'formik';
import React, { useState } from 'react';

import styled from '@emotion/styled';
import CanceledEyeIcon from '../Icons/CanceledEyeIcon';
import EyeIcon from '../Icons/EyeIcon';

interface IInputField {
  label?: any;
  id?: string;
  name: string;
  type: string;
  placeholder?: string;
  rpiSrc?: string;
  lpiSrc?: string;
  children?: any;
  className?: string;
}

export const SelectField: React.FC<
  Omit<IInputField, 'placeholder' | 'type'>
> = ({ label, lpiSrc, rpiSrc, className, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <InputWrapper className={className}>
      <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
      <InputContainer>
        <Select {...field} {...props} />
        {lpiSrc ? <LeftPlaceHolderCardIcon alt="icon" src={lpiSrc} /> : null}
        {rpiSrc ? <RightPlaceHolderCardIcon alt="icon" src={rpiSrc} /> : null}
      </InputContainer>
      {meta.touched && meta.error ? (
        <FieldErrorInfo>{meta.error}</FieldErrorInfo>
      ) : null}
    </InputWrapper>
  );
};

export const TextInputField: React.FC<IInputField> = ({
  label,
  lpiSrc,
  rpiSrc,
  className,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <InputWrapper className={className}>
      <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
      <InputContainer>
        <Input {...field} {...props} className={className ? className : ''} />
        {lpiSrc ? <LeftPlaceHolderCardIcon alt="icon" src={lpiSrc} /> : null}
        {rpiSrc ? <RightPlaceHolderCardIcon alt="icon" src={rpiSrc} /> : null}
      </InputContainer>
      {meta.touched && meta.error ? (
        <FieldErrorInfo>{meta.error}</FieldErrorInfo>
      ) : null}
    </InputWrapper>
  );
};

export const TextAreaInputField: React.FC<IInputField> = ({
  label,
  lpiSrc,
  rpiSrc,
  className,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <InputWrapper className={className}>
      <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
      <InputContainer>
        <TextArea
          {...field}
          {...props}
          className={className ? className : ''}
          rows={10}
        />
        {lpiSrc ? <LeftPlaceHolderCardIcon alt="icon" src={lpiSrc} /> : null}
        {rpiSrc ? <RightPlaceHolderCardIcon alt="icon" src={rpiSrc} /> : null}
      </InputContainer>
      {meta.touched && meta.error ? (
        <FieldErrorInfo>{meta.error}</FieldErrorInfo>
      ) : null}
    </InputWrapper>
  );
};

export const Checkbox: React.FC<Pick<IInputField, 'name' | 'children'>> = ({
  children,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <CheckboxWrapper>
      <label className="checkbox">
        <input type="checkbox" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </CheckboxWrapper>
  );
};

export const PasswordInputField: React.FC<IInputField> = ({
  label,
  lpiSrc,
  rpiSrc,
  className,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <PasswordWrapper className={className}>
      <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
      <PasswordContainer>
        <Password
          {...field}
          {...props}
          type={showPassword ? "text" : "password"}
          className={className ? className : ""}
        />
        {lpiSrc && <PasswordLeftIcon alt="icon" src={lpiSrc} />}
        <PasswordToggleIcon onClick={togglePasswordVisibility}>
          {showPassword ? <CanceledEyeIcon /> : <EyeIcon />}
        </PasswordToggleIcon>
      </PasswordContainer>
      {meta.touched && meta.error ? (
        <FieldErrorInfo>{meta.error}</FieldErrorInfo>
      ) : null}
    </PasswordWrapper>
  );
};

// styles just for PasswordInputField
const PasswordWrapper = styled.div`
  width: 100%;
  max-width: 40rem;
`;

const PasswordContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Password = styled.input`
  /* border: 2px solid green; */
  width: 100%;
  border-radius: 4px;
  padding: 1rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.blackBlack2};
  font-size: 1.125rem;
  border: 1px solid ${({ theme }) => theme.palette.greyGrey3};
  cursor: text;
  /* appearance: none; */

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.greyGrey1};
  }
  &:hover {
    cursor: text;
  }

  &::placeholder {
    color: 1px solid ${({ theme }) => theme.palette.greyGrey3};
    font-size: 1.125rem;
    font-weight: 300;

    //mobile-specific styles
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }

  //mobile-specific styles
  @media (max-width: 768px) {
    padding: 12px;
    font-size: 15px;
  }
`;

const PasswordToggleIcon = styled.div`
  position: absolute;
  right: 19px;
  cursor: pointer;
`;

const PasswordLeftIcon = styled.img`
  margin-right: 8px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  /* border: 1px solid darkblue; */

  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.greyGrey2};

  & > label {
    display: flex;
    align-items: center;
  }

  & > label > input[type='checkbox'] {
    color: red;
    margin-right: 10px; /* Adjust the value as needed to create the desired gap */
  }
`;

const FieldErrorInfo = styled.div`
  font-size: 1rem;
  color: red;
  /* border: 2px solid red;
  height: 1.4rem; */
  text-align: left;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const InputLabel = styled.label`
  font-weight: 400;
`;

interface IInput {
  textAlign?: string;
  lpiSrc?: string;
  rpiSrc?: string;
  contact?: string;
}

const Input = styled.input<IInput>`
  border: 2px solid white;
  border-radius: 4px;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-weight: bolder;
  color: ${({ theme }) => theme.palette.blackBlack2};

  &:focus {
    outline: none;
    border-color: 2px solid white;
  }
  &:hover {
    cursor: pointer;
  }

  &.mailingList {
    color: white;
    background-color: transparent;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    padding: 0.56rem 2.88rem;
    border-radius: 0.625rem;

    @media (max-width: 480px) {
      /* border: 2px solid red; */
      width: 100%;
    }
  }

  &.mailingList::placeholder {
    color: white;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
  }

  &.contact {
    border: 1px solid ${({ theme }) => theme.palette.greyGrey1};
    padding: 0.94rem 1.81rem;
    border-radius: 0.5rem;
    font-size: 1.25rem;
    font-weight: 400;

    @media (max-width: 480px) {
      width: 100%;
      /* border: 2px solid green;
      grid-column: span 2; */
    }
  }

  &.contact::placeholder {
    color: 1px solid ${({ theme }) => theme.palette.greyGrey3};
    font-size: 1.25rem;
    font-weight: 400;
  }
`;

const TextArea = styled.textarea<IInput>`
  /* border: 2px solid red; */
  border: 1px solid ${({ theme }) => theme.palette.greyGrey1};
  border-radius: 4px;
  padding: ${({ lpiSrc, rpiSrc }) => (lpiSrc || rpiSrc ? '5rem' : '1rem')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-weight: bolder;
  color: ${({ theme }) => theme.palette.blackBlack2};
  font-size: 0.9rem;
  resize: none;
  /* appearance: none; */

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.greyGrey1};
    /* background-color: ${({ theme }) => theme.palette.blackBlack2}; */
  }
  &:hover {
    cursor: pointer;
  }

  /* &::placeholder {
    color: #dad8d8;
    opacity: 1;
  } */

  &.contact {
    border: 1px solid ${({ theme }) => theme.palette.greyGrey1};
    padding: 0.94rem 1.81rem;
    border-radius: 0.5rem;
    font-size: 1.25rem;
    font-weight: 400;
  }

  &.contact::placeholder {
    color: 1px solid ${({ theme }) => theme.palette.greyGrey3};
    font-size: 1.25rem;
    font-weight: 400;
  }
`;

const Select = styled.select`
  /* border: 2px solid red; */
  border: 1px solid ${({ theme }) => theme.palette.greyGrey1};
  border-radius: 4px;
  padding: 1rem;
  font-weight: bolder;
  color: ${({ theme }) => theme.palette.blackBlack2};
  font-size: 0.9rem;

  appearance: none;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.greyGrey1};
  }
  &:hover {
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: grid;
  height: fit-content;
  /* border: 2px solid red; */

  & > * {
    grid-column: 1;
    grid-row: 1;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const LeftPlaceHolderCardIcon = styled.img`
  height: 1.5rem;
  display: grid;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
`;

export const RightPlaceHolderCardIcon = styled.img`
  height: 1.5rem;
  display: grid;
  margin-right: 1rem;
  margin-left: auto;
  margin-top: 0.8rem;
`;
