import { useFormikContext } from "formik";
import React from "react";

import styled from "@emotion/styled";

interface IValidatingFormSubmitButton {
  loading?: boolean;
  children: string;
  className?: string;
}

const ValidatingFormSubmitButton: React.FC<IValidatingFormSubmitButton> = ({
  loading,
  children,
  className,
}) => {
  const { errors, dirty, isValid } = useFormikContext<any>();
  console.log(errors);
  return (
    <FormSubmitButton
      type="submit"
      className={className}
      disabled={isValid && dirty ? false : true}
    >
      {children}
      {loading && "..."}
    </FormSubmitButton>
  );
};

export default ValidatingFormSubmitButton;

export const FormSubmitButton = styled.button`
  background-color: white;
  padding: 0.56rem 5.06rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blackBlack2};
  border-radius: 0.25rem;
  border: 1px solid transparent;
  font-size: 1.5rem;
  font-weight: 500;
  height: fit-content;
  cursor: pointer;

  &.contact {
    width: 100%;
    background-color: #bdbdbd;
    margin-top: 2rem;
    color: white;
  }

  &.tinyFont {
    font-size: 1.125rem;
  }

  &.lightGreyBg {
    background-color: #bdbdbd;
    color: white;
  }

  &.smallPadding {
    padding: 0.62rem 5rem;
  }

  &.fit-content {
    width: fit-content;
  }

  &.reset {
    background: ${({ theme }) => theme.palette.mainBlue};
    color: white;

    &:disabled {
      cursor: not-allowed;
      background: ${({ theme }) => theme.palette.greyGrey2};
    }

    // Mobile-specific styles
    @media (max-width: 768px) {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;
